<template>
  <div class="flex-col page">
    <div class="flex-col group_1">
      <div class="flex-col text-wrapper">
        <span class="text_1">选择维保公司</span>
      </div>
      <div class="box_1">
        <div class="flex-row section_2">
          <img src="../assets/sou.png" class="image_4" />
          <input type="text" class="text_3" v-model="choose" placeholder="请输入需要搜索的维保公司" @input="search(choose)"/>
        </div>
        <div v-if="show">
          <span class="text_2">热门维保公司</span>
          <div class="flex-col list">
            <van-radio-group v-model="radio">
              <van-radio :name="i" :key="i" v-for="(item, i) in listktblVhbX">{{item.CompanyName}}</van-radio>
            </van-radio-group>
          </div>
          <div class="flex-col items-center button" @click="chose()">
            <span>确定选择</span>
          </div>
        </div>
        <div v-if="!show">
          <span class="text_2">搜索结果</span>
          <img src="../assets/null.png" class="image_5" />
          <span class="text_4">很抱歉未找到相应结果</span>
          <div class="flex-col items-center button">
            <span>返回</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {company} from'@/api/Company/all'
export default {
  data() {
    return {
      choose: '',
      listktblVhbX: [],//热门
      listktblVhbXs:[],//搜索出来的数据
      radio: '',
      show: true
    }
  },
  mounted(){
    company().then(res=>{
      const list=res.data.Data
      this.listktblVhbXs=list
      this.listktblVhbX=list.slice(0,5)
    })
  },
  computed: {
    isLogin() {
      let boo = false
      if (this.password && this.Npassword) {
        boo = true
      }
      return boo
    }
  },
  methods:{
    search(cos){
      if(cos!==''){
        this.show=true
        this.listktblVhbX=[]
        company({"name":cos}).then(res=>{
          if(res.data.Data.length===0){
            this.show=false
          }else{
            this.listktblVhbX=res.data.Data
          }
        })
      }else{
        this.show=true
        company().then(res=>{
          const list=res.data.Data
          this.listktblVhbXs=list
          this.listktblVhbX=list.slice(0,5)
        })
      }
    },
    chose(){
      if(this.radio!==''){
        sessionStorage.setItem('address',this.listktblVhbX[this.radio].CompanyName)
        sessionStorage.setItem('address-id',this.listktblVhbX[this.radio].id)
        this.$router.push('/maintenance/apply')
      }
    }
  }
}
</script>

<style scoped>
@import '../assets/common.css';
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.image_4 {
  width: 1.5rem;
  height: 1.5rem;
}
.page {
  width: 100%;
  overflow: hidden;
}
.header {
  padding-top: 0.063rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  height: 2.81rem;
}
.group_1 {
  flex: 1 1 auto;
  overflow-y: auto;
}
.section_1 {
  padding: 0.88rem 0.81rem;
  background-color: rgb(237, 237, 237);
}
.box_1 {
  overflow-x: hidden;
  margin-top: -1.5rem;
  background-color: #fff;
  border-radius: 1.2rem 1.2rem 0 0;
  padding-top: 2.5rem;
}
.section_2 {
  margin: 0 1.25rem 0;
  padding: 1rem 0.88rem 0.94rem;
  color: rgb(153, 153, 153);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  background: #f5f5f5;
  display: flex;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;
}
.button {
  margin: 3.69rem 1.25rem 8rem;
  padding: 1.13rem 0 1rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  white-space: nowrap;
  border-radius: 0.3rem;
  background: #005ddb;
  background-repeat: no-repeat;
}
.button_1 {
  background-color: #005ddb;
  color: #fff;
}
.image_5 {
  display: block;
  margin: 3rem auto 0;
}
.text-wrapper {
  padding: 5.88rem 0 6.75rem;
  color: rgb(255, 255, 255);
  font-size: 1.88rem;
  line-height: 1.75rem;
  white-space: nowrap;
  background: #0355d7 url(../assets/bg.png) right no-repeat;
}
.text_2 {
  display: block;
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  line-height: 1.19rem;
  white-space: nowrap;
  margin-left: 1.31rem;
  margin-top: 1.25rem;
}
.text_3 {
  flex: 1;
  margin-left: 0.5rem;
  border: 0;
  outline: none;
  background: #f5f5f5;
}
.text_4 {
  display: block;
  text-align: center;
  margin-top: 1.5rem;
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.text_1 {
  text-align: left;
  margin-left: 1.31rem;
}
.van-radio-group {
  padding: 1.5rem 1.25rem 0;
}
.van-radio {
  padding-bottom: 1.25rem;
}
</style>
